<template>
  <div style="background-color: #fff">
    <div class="topNav">
      <div
        :style="{ width: this.$oucy.pageWidth + 'px', display: 'inline-block' }"
      >
        <!--图标logo-->
        <div class="df-jc-s-b">
          <div class="df-ai-c">
            <img
              src="../../assets/logo.png"
              class="logo m-r-25 pointer"
              @click="$oucy.go('/')"
            />
          </div>
          <!--搜索 组件-->
          <search-product2 @ourShop="ourShop"></search-product2>
        </div>
        <div></div>
        <!--选项卡-->
        <div class="navi-menu" v-if="0">
          <el-row>
            <el-col :span="4">
              <div @click="$oucy.replace('/', null)" class="navi-menu-ele">
                首页
              </div>
            </el-col>
            <el-col :span="4">
              <div
                @click="$oucy.replace('/furniture', null)"
                class="navi-menu-ele"
              >
                家具库
              </div>
            </el-col>
            <el-col :span="4">
              <div
                @click="$oucy.replace('/material', null)"
                class="navi-menu-ele"
              >
                材料库
              </div>
            </el-col>
            <el-col :span="4">
              <div
                @click="$oucy.replace('/enterprise', null)"
                class="navi-menu-ele"
              >
                企业库
              </div>
            </el-col>
            <el-col :span="4">
              <div @click="$oucy.replace('/card', null)" class="navi-menu-ele">
                名片库
              </div>
            </el-col>
            <el-col :span="4" v-if="0">
              <div class="navi-menu-ele">物流库</div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="topCenter" v-if="enterpriseinfo">
      <div
        :style="{ width: this.$oucy.pageWidth + 'px' }"
        class="topCenterBody"
      >
        <img
          :src="$oucy.ossUrl + enterpriseinfo.enterpriseAvata"
          class="merchantLogo"
          v-if="enterpriseinfo && enterpriseinfo.enterpriseAvata"
        />
        <div class="merchantText p-l-25">
          <div class="df-jc-s-b df-ai-c">
            <div
              class="f24 pointer"
              @click="
                $oucy.replace('/Shop?enterpriseId=' + detail.enterpriseId, null)
              "
            >
              {{ enterpriseinfo.enterpriseShopName }}
              <!-- <img src="@/assets/icon/furnisher_icon.png" class="m-r-5"> -->
              <enterprise-grade :enterprise="enterpriseinfo" />
            </div>
            <div class="df-ai-c f14 c-6">
              <span @click="decoderScene" class="df-ai-c pointer m-r-30">
                <img class="m-r-5" src="@/assets/icon/share_icon.png" />
                分享
              </span>
              <span
                class="df-ai-c pointer"
                @click="saveCollectUser(enterpriseinfo.id)"
              >
                <i
                  :class="
                    isCollect ? 'el-icon-star-on f20' : 'el-icon-star-off f16'
                  "
                  class="m-r-5"
                ></i>
                {{ isCollect ? "已" : "" }}收藏
              </span>
            </div>
          </div>
          <div class="f14 m-t-20 c-6">
            <span class="">星级</span>
            <el-rate
              class="m-r-30"
              v-model="enterpriseinfo.enterpriseStar / 2"
              disabled
              disabled-void-color="#888888"
              void-color="#888888"
              score-template="{value}"
              style="display: inline-block; position: relative; top: -2px"
            ></el-rate>

            <span class="m-r-30">品质{{ enterpriseinfo.enterpriseScore }}</span>
            <span>粉丝{{ enterpriseinfo.enterpriseFansCount }}</span>
          </div>
          <div class="m-t-20">
            <el-button-group>
              <!--                            <el-button type="" size="mini">在线客服</el-button>-->
              <!--                            <el-button type="" size="mini">店铺海报</el-button>-->
              <el-button type="" size="mini">意见反馈</el-button>
            </el-button-group>
          </div>
        </div>
      </div>
    </div>
    <div class="product topBottom">
      <div
        :style="{ width: this.$oucy.pageWidth + 'px' }"
        style="margin-left: auto; margin-right: auto"
      >
        <div class="df-jc-s-b">
          <div style="margin-left: -20px; position: relative; z-index: 99">
            <el-menu class="el-menu-demo" mode="horizontal" @select="">
              <el-menu-item
                index="1"
                @click="
                  $oucy.replace(
                    '/Shop?enterpriseId=' + detail.enterpriseId,
                    null
                  )
                "
              >
                首 页
              </el-menu-item>
              <el-menu-item
                index="1"
                @click="
                  $oucy.replace(
                    '/Shop?navType=spu&enterpriseId=' + detail.enterpriseId,
                    null
                  )
                "
              >
                全部产品
              </el-menu-item>
              <el-submenu index="2">
                <template slot="title">产品分类</template>
                <el-menu-item
                  index="2-1"
                  v-for="(v, i) of byLtUserAuthAndClassifyCategoryList"
                  :key="i"
                >
                  {{ v.classifyName }}
                </el-menu-item>
              </el-submenu>
              <el-menu-item index="3" @click="gotoCard">企业名片</el-menu-item>
            </el-menu>
          </div>
          <div class="text-right">
            <div class="input-container">
              <el-input
                style="width: 300px"
                placeholder="请输入搜索内容"
                v-model="spuName"
                class="input-with-select"
              >
                <el-button
                  @click="goSearch"
                  type="primary"
                  slot="append"
                  style="width: 70px; padding-left: 5px"
                >
                  信息搜索
                </el-button>
              </el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="breadcrumbBarBox">
      <div
        class="pageCenter breadcrumbBar"
        :style="{ width: this.$oucy.pageWidth + 'px' }"
        v-if="0"
      >
        <el-breadcrumb separator="/">
          <el-breadcrumb-item
            v-for="(item, index) in levelList"
            :key="index"
            :to="item.path"
            class="breadcrumb-item"
          >
            {{ item.meta.title }}
          </el-breadcrumb-item>
        </el-breadcrumb>
        <!-- <el-button icon="el-icon-notebook-2" type="text">帮助手册</el-button> -->
      </div>
    </div>
    <div
      :style="{ width: this.$oucy.pageWidth + 'px', display: 'inline-block' }"
      class="m-t-40"
    >
      <div class="middle">
        <div class="middle_top">
          <el-row>
            <el-col :span="8" class="">
              <!-- 大图组件 -->
              <carousel
                :carousel="carousel"
                :clickImgUrl="clickImg"
                @change="clickImg = null"
              ></carousel>
              <div class="df-jc-s-b p-l-5 p-r-25 c-2 f12 m-t-10">
                <div class="bml df-jc-s-b">
                  <!-- <span class="df-ai-c pointer m-r-30"><img class="m-r-5" src="@/assets/icon/star_icon_not.png">收藏</span> -->
                  <span class="df-ai-c pointer m-r-30" @click="decoderScene2">
                    <img class="m-r-5" src="@/assets/icon/share_icon.png" />
                    分享
                  </span>
                  <span
                    class="df-ai-c pointer"
                    @click="saveCollectUser(spuId,2)"
                  >
                    <i
                      :class="
                        isSpuCollect ? 'el-icon-star-on f20' : 'el-icon-star-off f16'
                      "
                      class="m-r-5"
                    ></i>
                    {{ isSpuCollect ? "已" : "" }}收藏
                  </span>
                </div>
                <div class="bmr">举报</div>
              </div>
            </el-col>
            <el-col :offset="1" :span="12">
              <div class="goods_conditon text-left">
                <span>{{ detail.spuName }}</span>
              </div>
              <el-row class="m-t-10">
                <el-col :span="3" v-if="detail.spuIsNew">
                  <div class="label1">
                    <span
                      style="
                        font-size: 12px;
                        color: #888888;
                        width: 66px;
                        line-height: 12px;
                      "
                    >
                      新品
                    </span>
                  </div>
                </el-col>
                <el-col :span="3" v-if="detail.spuIsSpecial">
                  <div class="label1">
                    <span
                      style="
                        font-size: 12px;
                        color: #888888;
                        width: 66px;
                        line-height: 12px;
                      "
                    >
                      特价
                    </span>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="label1">
                    <span
                      style="
                        font-size: 12px;
                        color: #888888;
                        width: 66px;
                        line-height: 12px;
                      "
                    >
                      库存
                    </span>
                  </div>
                </el-col>
              </el-row>
              <div style="text-align: left">
                <div class="price">
                  <el-row class="df-jc-s-b df-ai-c">
                    <el-col :span="2">
                      <p>价格</p>
                      <!-- <p>领券</p> -->
                    </el-col>
                    <el-col :span="11" class="text-left">
                      <div v-if="select">
                        <span
                          style="
                            width: 131px;
                            height: 21px;
                            font-size: 28px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #ed6732;
                            text-align: left;
                          "
                          v-if="select.price == '0.00' || select.price == '0'"
                        >
                          面议
                        </span>
                        <span
                          style="
                            width: 131px;
                            height: 21px;
                            font-size: 28px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #ed6732;
                            text-align: left;
                          "
                          v-else
                        >
                          ￥{{ select.price }}
                          <!--                                                 联系工厂获取价格-->
                        </span>
                      </div>
                      <div v-else>
                        <span
                          style="
                            width: 131px;
                            height: 21px;
                            font-size: 28px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #ed6732;
                            text-align: left;
                          "
                          v-if="
                            detail.spuPrise == '0.00' || detail.spuPrise == '0'
                          "
                        >
                          面议
                        </span>
                        <span
                          style="
                            width: 131px;
                            height: 21px;
                            font-size: 28px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #ed6732;
                            text-align: left;
                          "
                          v-else
                        >
                          ￥{{ detail.spuPrise }}
                          <!--                                                  联系工厂获取价格-->
                        </span>
                      </div>
                      <!--                    <div class="coupon">
                                           <img src="../../assets/goods/coupon2.png">
                                           <img src="../../assets/goods/coupon1.png">
                                       </div> -->
                    </el-col>
                    <el-col :span="11">
                      <div class="df-jc-s-b df-ai-c f12">
                        <div class="price_right m-r-20">
                          <span v-if="select && select.quantity != null">
                            库存&nbsp&nbsp{{ select.quantity }}
                          </span>
                          <span v-else>
                            库存&nbsp&nbsp{{ detail.spuRepertory }}
                          </span>
                        </div>
                        <div class="price_right m-r-20">
                          <span>
                            累计销量&nbsp&nbsp{{ detail.spuSoldAmount }}
                          </span>
                        </div>
                        <div
                          class="price_right pointer m-r-20"
                          @click="enlargeCode"
                        >
                          <span>
                            手机购买&nbsp&nbsp
                            <img
                              src="../../assets/QRCode/qr_code.png"
                              style="vertical-align: middle"
                            />
                          </span>
                          <div
                            ref="myLoginQrCodeUrl"
                            v-show="isPhone"
                            class="payQRCode"
                            style="
                              position: absolute;
                              right: 0;
                              top: 60px;
                              z-index: 999;
                            "
                          ></div>
                          <!-- <img src="../../assets/QRCode/purchase_code.png" v-if="isPhone" style="position: absolute;right: 0;top: 60px;"> -->
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                  <VIPPrice :data="detail"></VIPPrice>
                  <div
                    class="df-ai-c p-10"
                    v-if="productTicketForUser && productTicketForUser.length"
                  >
                    <div class="f14">领券</div>
                    <div class="df coupons">
                      <div
                        class="text-center m-l-10 m-b-5 m-t-5 coupon pointer"
                        @click="takeCoupons(v, i)"
                        :class="[
                          'coupon' + v.ticketPoolBasic.ticketKind,
                          !v.valid ? 'coupon-1' : '',
                        ]"
                        v-for="(v, i) of productTicketForUser" :key="i"
                      >
                        <div class="f14 bold">
                          满{{ v.ticketPoolBasic.ticketLimitFee }}减{{
                            v.ticketPoolBasic.ticketFee
                          }}
                        </div>
                        <div class="f12" v-if="v.valid">-点击领取-</div>
                        <div class="f12" v-else>-已领取-</div>
                      </div>
                    </div>
                  </div>
                </div>
                <el-row class="text-left m-t-10">
                  <el-col :span="8" v-if="0">
                    <div class="demo-input-suffix">
                      <span class="f12 c-2 m-r-5">配送至</span>
                      <el-select
                        v-model="value"
                        size="mini"
                        placeholder="请选择"
                        style="width: 150px"
                      >
                        <el-option
                          v-for="item in options2"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </div>
                  </el-col>
                  <el-col :span="8" v-if="0">
                    <div class="demo-input-suffix">
                      <span class="f12 c-2 m-r-5">物流</span>
                      <el-select
                        v-model="value"
                        size="mini"
                        placeholder="请选择"
                        style="width: 150px"
                      >
                        <el-option
                          v-for="item in options3"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div
                      style="
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #aaaaaa;
                      "
                      class="f12 m-t-5"
                    >
                      运费
                      <span class="" style="color: #ed6732">自提</span>
                    </div>
                  </el-col>
                </el-row>
 
                <div class="hr dashed"></div>
                <div class="text-left">
                  <div v-for="(v, i) of detail.props" :key="i" v-if="v.attrName">
                    <div class="m-t-20 df-ai-c">
                      <span class="hLine"></span>
                      {{ v.attrName }}
                    </div>
                    <div class="z-skus">
                      <div
                        v-for="(vv, ii) of v.furnitureAttrOption"
                        class="size1 pointer z-sku"
                        :class="vv.select ? 'select' : ''"
                        @click="setSelect(v, i, vv, ii)"
                        :title="vv.optionValue"
                        :key="ii"
                      >
                        <img
                          :src="$oucy.ossUrl + vv.optionImg"
                          v-if="vv.optionImg && vv.optionImg!=null"
                          @click="clickImg = $oucy.ossUrl + vv.optionImg"
                        />
                        <div class="p-l-10 optionValue" v-else>
                          {{ vv.optionValue }}
                        </div>
                        <img
                          src="@/assets/images/stockCar/corner.png"
                          class="corner"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="hr dashed"></div>
                <!-- <el-divider content-position="left"></el-divider> -->
                <div class="text-left">
                  <span
                    style="
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      color: #aaaaaa;
                    "
                    class="f12"
                  >
                    数量
                  </span>
                  <el-input-number
                    v-model="num"
                    :min="1"
                    size=""
                    class="m-l-10"
                    round
                  ></el-input-number>
                  <el-button
                    @click="addStock"
                    size=""
                    type="danger"
                    round
                    class="m-l-10"
                  >
                    加入进货车
                  </el-button>
                  <el-button
                    type="primary"
                    size=""
                    @click="
                      $oucy.go(
                        '/acc/confirmOrder?skuId=' +
                          select.skuId +
                          '&skuNum=' +
                          num
                      )
                    "
                    round
                  >
                    立即购买
                  </el-button>
                </div>

                <!--                                <div class="text-left" v-else>-->
                <!--                                  <el-button size="" type="primary" round class="m-l-10" v-if="enterpriseinfo && enterpriseinfo.enterpriseAuthenticationDto">联系人：{{getName()}}</el-button>-->
                <!--                                  <el-button size="" type="primary" round class="m-l-10" v-else-if="enterpriseinfo && enterpriseinfo.enterpriseLegalPerson">联系人：{{enterpriseinfo.enterpriseLegalPerson}}</el-button>-->
                <!--                                  <el-button size="" type="danger" round class="m-l-10" @click="showPhone">查看工厂电话</el-button>-->
                <!--                                </div>-->
              </div>
            </el-col>
            <el-col :span="2" :offset="1" v-if="0">
              <div class="">
                <span class="guessWhatYouLike">-猜你喜欢-</span>
                <el-carousel
                  height="400px"
                  indicator-position="none"
                  arrow="never"
                  :interval="5000"
                >
                  <el-carousel-item v-for="item in 3" :key="item">
                    <div class="rightCarouseBox">
                      <img
                        src="../../assets/goods/goods1.png"
                        class="rightCarouselImg"
                      />
                      <div class="goods_name2 line-1">
                        <span>#33306&nbsp皮质弹簧床</span>
                      </div>
                      <img
                        src="../../assets/goods/goods2.png"
                        class="rightCarouselImg"
                      />
                      <div class="goods_name2 line-1">
                        <span>#33306&nbsp皮质弹簧床</span>
                      </div>
                      <img
                        src="../../assets/goods/goods3.png"
                        class="rightCarouselImg"
                      />
                      <div class="goods_name2 line-1">
                        <span>#33306&nbsp皮质弹簧床</span>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="middle_bottom m-t-50">
          <!-- <div class="middle_bottom_left"> -->
          <el-row>
            <el-col :span="5">
              <div class="com_card">
                <p v-if="enterpriseinfo">{{ enterpriseinfo.enterpriseName }}</p>
                <div class="df-jc-s-a f12">
                  <div
                    class="pointer"
                    @click="
                      $oucy.go(
                        '/Shop?enterpriseId=' + detail.enterpriseId,
                        null
                      )
                    "
                  >
                    <img src="@/assets/images/product/dianpu.png" alt="" />
                    <div>进入商铺</div>
                  </div>
                  <div class="pointer" @click="openMessage()">
                    <img src="@/assets/images/product/dianpu.png" alt="" />
                    <div>在线联系</div>
                  </div>
                </div>
                <!-- <img src="../../assets/icon/enter_store.png" class="pointer"> -->
                <!-- <img src="../../assets/icon/relate_icon.png" class="pointer" @click="openMessage()"> -->
              </div>
              <table class="store_kind">
                <th>产品分类</th>
                <tr
                  v-for="(v, i) of byLtUserAuthAndClassifyCategoryList"
                  :key="i"
                  @click="gotoShop(v)"
                >
                  <td>
                    <el-button type="text">{{ v.classifyName }}</el-button>
                  </td>
                </tr>
              </table>
              <table class="customer_service">
                <th>店内客服</th>
                <tr
                  v-for="(v, i) of enterpriseCustomer"
                  :key="i"
                  @click="openImMessage(v)"
                >
                  <td class="df-ai-c p-l-15">
                    <img
                      :src="$oucy.ossUrl + v.detailAvatar"
                      v-if="v.detailAvatar"
                    />
                    <img
                      src="../../assets/icon/customer_service_icon.png"
                      v-else
                    />
                    <el-button type="text" class="m-l-10 line-1">
                      {{ v.customerNickname || v.detailNickname }}
                    </el-button>
                  </td>
                </tr>
              </table>
              <table class="sales_rank" v-if="detail.salesRank">
                <th v-if="0">
                  <div style="width: 210px; margin-top: 2px">
                    <img src="../../assets/icon/crown.png" />
                  </div>
                  -销量排行-
                </th>
                <tr v-for="(v, i) of detail.salesRank" @click="replace(v)" :key="i">
                  <td>
                    <img
                      :src="$oucy.ossUrl + v.cover"
                      style="width: 188px; height: 188px"
                    />
                    <div class="goods_name line-1">
                      <span>{{ v.spuName }}</span>
                    </div>
                  </td>
                </tr>
              </table>
            </el-col>
            <el-col :span="19">
              <div class="goods_comment">
                <el-tabs type="card" v-model="activeName">
                  <el-tab-pane label="产品介绍" name="first">
                    <div class="top text-left">产品参数</div>
                    <div style="width: 100%; padding: 20px">
                      <div
                        style="
                          display: flex;
                          flex-wrap: wrap;
                          text-align: left;
                          font-size: 12px;
                          color: #666;
                        "
                      >
                        <div
                          v-for="(v, i) of detail.groupProps"
                          :key="i"
                          style="
                            width: 33%;
                            margin-bottom: 20px;
                            padding-right: 10px;
                            box-sizing: border-box;
                          "
                          class="line-1"
                          :title="v.optionValue"
                        >
                          <div>{{ v.optionName }}：{{ v.optionValue }}</div>
                        </div>
                      </div>
                    </div>
                    <el-divider></el-divider>
                    <div v-html="detail.spuDescribe" class="vhtml"></div>
                    <!-- <img src="../../assets/goods/goods_detail.png" style="width: 88%"> -->
                  </el-tab-pane>
                  <el-tab-pane :label="secondText" name="second">
                    <div class="right_button">
                      <el-button
                        type="text"
                        :class="{
                          select:
                            QueryFurnitureLibrary.orderSchemeSpuSortEnum == 1,
                        }"
                        @click="SetOrderSchemeSpuSortEnum(1)"
                      >
                        按时间排序
                      </el-button>
                      <span
                        style="
                          padding: 10px;
                          color: #dddddd;
                          border-radius: 1px;
                        "
                      >
                        |
                      </span>
                      <el-button
                        type="text"
                        :class="{
                          select:
                            QueryFurnitureLibrary.orderSchemeSpuSortEnum == 0,
                        }"
                        @click="SetOrderSchemeSpuSortEnum(0)"
                      >
                        按默认排序
                      </el-button>
                    </div>
                    <div class="comment">
                      <div
                        class="comment_1 m-t-10"
                        v-for="(v, i) of commentSpuList.content"
                        :key="i"
                      >
                        <el-row>
                          <el-col :span="2">
                            <img
                              :src="$oucy.ossUrl + v.orderUserauthAvata"
                              style="
                                margin-left: 15px;
                                margin-top: 20px;
                                width: 48px;
                                height: 48px;
                                border-radius: 48px;
                              "
                            />
                            <span class="username line-1">
                              {{ v.orderUserauthNickname }}
                            </span>
                          </el-col>
                          <el-col :span="22">
                            <div class="comment_right">
                              <div>
                                <p class="comment_right_p1 f14 c-6">
                                  {{ v.commentContent }}
                                </p>
                              </div>
                              <div
                                class="comment_img"
                                v-if="v.commentAttachDtoList"
                              >
                                <MyMedia
                                  :srcList="
                                    $oucy.getSrcList(
                                      v.commentAttachDtoList,
                                      'attachUrl'
                                    )
                                  "
                                />
                                <!--  <el-image v-for="(vv,ii) of v.commentAttachDtoList" style="width: 100px; height: 100px;margin-right:5px" :src="$oucy.ossUrl +vv.attachUrl" :preview-src-list="getSrcList(v.commentAttachDtoList)">
                                                                </el-image> -->
                              </div>
                              <div
                                class="f12 c-2 m-t-10"
                                v-if="v.orderSchemeSpus && v.orderSchemeSpus[0]"
                              >
                                {{ v.orderSchemeSpus[0].skuName }}
                              </div>
                              <div class="df-jc-s-b df-ai-c">
                                <div class="df">
                                  <span v-if="v.commentEvaluate == 1">
                                    非常差
                                  </span>
                                  <span v-else-if="v.commentEvaluate == 2">
                                    差评
                                  </span>
                                  <span v-else-if="v.commentEvaluate == 3">
                                    一般
                                  </span>
                                  <span v-else-if="v.commentEvaluate == 4">
                                    好评
                                  </span>
                                  <span v-else-if="v.commentEvaluate == 5">
                                    非常好
                                  </span>
                                  <el-rate
                                    class="m-t-10"
                                    disabled
                                    v-model="v.commentEvaluate"
                                    style="margin: 15px 10px"
                                  ></el-rate>
                                </div>
                                <div>
                                  <span>{{ v.commentCreatetime }}</span>
                                </div>
                              </div>
                              <div class="comment_reply" v-if="v.commentReply">
                                <el-row>
                                  <el-col :span="1">
                                    <img
                                      src="../../assets/icon/bussiness_icon.png"
                                    />
                                  </el-col>
                                  <el-col :span="22">
                                    <span>商家回复</span>
                                  </el-col>
                                </el-row>
                                <div class="m-t-15 comment_right_p1">
                                  {{ v.commentReply }}
                                </div>
                              </div>
                            </div>
                            <div class="pagination"></div>
                          </el-col>
                        </el-row>
                      </div>
                    </div>
                    <el-pagination
                      class="m-t-10"
                      v-if="commentSpuList.content"
                      background
                      layout="sizes, prev, pager, next, jumper, ->, total, slot"
                      :page-size="commentSpuList.size"
                      :total="commentSpuList.totalElements"
                      @size-change="sizeChange"
                      @current-change="currentChange"
                      @prev-click="currentChange"
                      @next-click="currentChange"
                    ></el-pagination>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="footer_footer">
          <table>
            <tr>
              <td style="line-height: 5px">
                <img
                  src="../../assets/images/header_logo.png"
                  style="width: 68px; height: 24px; margin-right: 10px"
                />
              </td>
              <td><span>|</span></td>
              <td>
                <img
                  src="@/assets/images/product/VIPban.png"
                  v-if="enterpriseinfo.vipLeve != 0"
                  style="vertical-align: middle"
                />
                <span v-else>入驻版</span>
              </td>
            </tr>
          </table>
        </div>
        <!--                 <el-dialog title="添加进货车方案" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
                    <el-input type="text" placeholder="请输入方案名称" v-model="text" maxlength="20" show-word-limit>
                    </el-input>
                    <div style="margin: 20px 0;"></div>
                    <el-input type="textarea" placeholder="请输入方案描述" v-model="textarea" maxlength="120" :autosize="{ minRows: 6}" show-word-limit>
                    </el-input>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="dialogVisible = false,drawer = true">添加</el-button>
                    </span>
                </el-dialog> -->
        <el-drawer
          :visible.sync="drawer"
          :with-header="false"
          :modal="false"
          :size="size"
        >
          <el-row>
            <el-col :span="8">
              <div class="drawer_left_top">
                <el-button type="text">
                  <img src="../../assets/icon/add_icon.png" />
                  新建方案
                </el-button>
                <el-button type="text">
                  <img src="../../assets/icon/price_sort_icon.png" />
                  价格排序
                </el-button>
              </div>
            </el-col>
            <el-col :span="16">
              <span class="fangan_name">欧简沙发</span>
              <el-checkbox
                :indeterminate="false"
                v-model="checkAll"
                @change="handleCheckAllChange"
                style="float: right; padding: 20px"
              >
                全选
              </el-checkbox>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <div class="cart_left">
                <div class="product_name">
                  <ul>
                    <li v-for="item in list" :key="item.id">
                      <el-row>
                        <el-col>
                          <span style="float: left">特洛伊床</span>
                          <img
                            src="../../assets/icon/edit.png"
                            style="float: right"
                          />
                        </el-col>
                      </el-row>
                      <el-row>
                        <span style="float: left; color: #aaaaaa">x3</span>
                      </el-row>
                      <el-row>
                        <span
                          style="float: left; font-size: 16px; color: #ed6732"
                        >
                          ￥30000
                        </span>
                      </el-row>
                    </li>
                  </ul>
                </div>
              </div>
            </el-col>
            <el-col :span="16">
              <el-row>
                <el-col :span="2">
                  <el-checkbox></el-checkbox>
                </el-col>
                <el-col :span="6">
                  <img src="../../assets/goods/goods20.png" />
                </el-col>
                <el-col :span="16">
                  <el-row>
                    <div class="product_condition">
                      <el-col>
                        <p>布艺沙发北欧简约现代客厅沙发大小户型科技布乳胶...</p>
                      </el-col>
                      <el-col>
                        <span class="span1">#C0001双人位置</span>
                        &nbsp
                        <span class="span1">米白色</span>
                        <el-button type="text" size="mini" style="float: right">
                          删除
                        </el-button>
                      </el-col>
                      <el-col>
                        <span class="span2">￥23000</span>
                        <el-input-number
                          size="mini"
                          v-model="num2"
                          style="float: right"
                        ></el-input-number>
                      </el-col>
                    </div>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-drawer>
      </div>
    </div>
    <!-- 添加新方案 | 修改方案 -->
    <el-dialog
      title="添加新方案"
      :visible.sync="createSchemaDialogVisible"
      width="500px"
      :before-close="handleClose"
    >
      <!-- <span>这是一段信息</span> -->
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="方案名称" prop="schemeName">
          <el-input
            v-model="ruleForm.schemeName"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="方案内容" prop="schemeInformation">
          <el-input
            type="textarea"
            v-model="ruleForm.schemeInformation"
            maxlength="120"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 加入进货车 -->
    <el-dialog
      title="加入进货车"
      :visible.sync="AddStockDialogVisible"
      width="400px"
      :before-close="handleClose"
    >
      <!-- <span>这是一段信息</span> -->
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="选择方案">
          <el-select v-model="AddStockValue" placeholder="请选择">
            <el-option
              v-for="item in allScheme"
              :key="item.schemeId"
              :label="item.schemeName"
              :value="item.schemeId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="AddStockDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="appendSku">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分享海报 -->
    <sharePoster ref="sharePoster"></sharePoster>
  </div>
</template>
<script>
  import { localSet, localGet, localDel } from "@/store/store";
  import {
    spu,
    furnitureClassify,
    stock,
    enterpriseinfo,
    cardEnterprise,
    spuComment,
    collectuser,
    scence,
    poster,
    ticket,
  } from "@/service";
  import QRCode from "qrcodejs2";
  export default {
    name: "product",
    data() {
      return {
        checkAll: "",
        num: 1,
        num2: 1,
        secondText: "产品评价",
        activeName: "first", // 'second',//'first',
        labelPosition: "left",
        isChoose: false,
        isPhone: false,
        isActive: "0",
        text: "",
        textarea: "",
        drawer: false,
        tabPosition: "left",
        list: [1, 2, 3],
        size: "660px",
        goodsList: [],
        srcList: [],
        // 头部
        searchClassifyVal: "",
        searchClassify: "1",
        topoptions: [
          {
            value: "1",
            label: "乐搜家导航",
          },
        ],
        options2: [
          {
            value: "1",
            label: "广州市/天河区",
          },
        ],
        options3: [
          {
            value: "1",
            label: "南康",
          },
        ],
        value: "1",
        levelList: [],
        spuId: null,
        // 详情
        detail: {},
        // 选中列表
        select: null,
        // 店内分类列表
        byLtUserAuthAndClassifyCategoryList: [],
        carousel: [],

        dialogVisible: !true,
        // 进货车
        rules: {
          schemeName: [
            { required: true, message: "请输入方案名称", trigger: "change" },
          ],
          schemeInformation: [
            { required: true, message: "请输入方案内容", trigger: "blur" },
          ],
        },
        createSchemaDialogVisible: false,
        AddStockDialogVisible: false,
        AddStockValue: null,
        allScheme: [],
        ruleForm: {
          schemeName: "",
          schemeIsPublic: true,
          schemeDefault: true,
          schemeInformation: "",
        },
        clickImg: null,
        spuName: null,
        QueryFurnitureLibrary: {
          limit: 20,
          start: 0,
          orderSchemeSpuSortEnum: 0, //0 最新排序 1默认排序
          spuId: null,
        },
        commentSpuList: {},
        enterpriseinfo: {},
        loginUser: {},
        enterpriseCustomer: [],
        productTicketForUser: [],
        isCollect: false,
        isSpuCollect: false,
        show: null,
        url: null,
        url1: null,
        url2: null,
      };
    },
    mounted() {
      this.spuId = this.$route.query.spuId;
      this.QueryFurnitureLibrary.spuId = this.spuId;
      this.getCommentSpuList();
      this.querySpu();
      setTimeout(()=>{
          let loginUser=localGet(this.$oucy.userInfoKey)
          if(loginUser && loginUser.id){
              this.getIsCollect(this.spuId,2);
          }
      },5000)
      
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
      this.creatQrCode(
        "https://h5.lesoujia.com/#/furniture/productDetail/productDetail?id=" +
          this.spuId
      );
    },
    watch: {
      /*        $route: {
                    handler(to, from) {
                        this.getBreadcrumb()
                    },
                    deep: true,
                    immediate: true
                }*/
    },
    methods: {
      creatQrCode(text) {
        const me = this;
        if (me.qrCode == null) {
          me.qrCode = new QRCode(this.$refs.myLoginQrCodeUrl, {
            text: text,
            // text: me.$oucy.baseHost+'/routerView/wechartLogin/'+me.$oucy.scanKeyOfPreLogin+'?p='+me.$oucy.getUUID(),
            width: 146,
            height: 146,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });

          console.log("creatQrCode");
        }
      },
      getName() {
        let username =
          this.enterpriseinfo.enterpriseAuthenticationDto
            .authenticationLegalPersonName;
        let IdCard =
          this.enterpriseinfo.enterpriseAuthenticationDto
            .authenticationLegalPersonIdCard;
        let name = username[0];
        let sex = IdCard[16];
        // 身份证第17位的数字有两个功能：
        // 一是区别性别：奇数表示男性，偶数表示女性。
        if (sex == 1) {
          name += "先生";
        } else {
          name += "女士";
        }
        return name;
      },
      replace(v) {
        this.$oucy.replace("/product?spuId=" + v.id);
        this.spuId = v.id;
        this.querySpu();
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 0);
      },
      handleClose(c) {
        c();
      },
      handleCheckAllChange() {},
      goSearch() {
        if (!this.spuName) {
          this.$message("请输入你要搜索的内容");
        } else {
          this.$oucy.go(
            `/Shop?enterpriseId=${this.detail.enterpriseId}&spuName=${this.spuName}`
          );
        }
      },
      lisChange(index) {
        console.log(index);
        this.$refs.remarkCarusel.setActiveItem(index);
        this.isActive = index;
      },
      imgScc: function () {
        this.isChoose = !this.isChoose;
      },
      enlargeCode: function () {
        this.isPhone = !this.isPhone;
        // alert('dsgsdgdf')
      },
      getBreadcrumb() {
        //$route.matched是一个数组 包含当前路由所有嵌套路径的路由记录
        // let matched = this.$route.matched.filter(item => item.name)
        // const first = matched[0];
        // if (first && first.name !== '首页') {
        //     matched = [{ path: '/', meta: { title: '首页' } }].concat(matched)
        // }

        this.levelList = [
          { path: "/", meta: { title: "首页" } },
          {
            path: "/Shop?enterpriseId=" + this.detail.enterpriseId,
            meta: { title: this.enterpriseinfo.enterpriseName },
          },
          { meta: { title: "产品详细页" } },
        ];
      },
      // 查询商品详情
      querySpu() {
        spu.querySpu({ spuId: this.spuId }).then((res) => {
          if (res.props && res.props.length) {
            for (let v of res.props) {
              for (var i = 0; i < v.furnitureAttrOption.length; i++) {
                let vv = v.furnitureAttrOption[i];
                if (i == 0) {
                  vv.select = true;
                } else {
                  vv.select = false;
                }
              }
            }
            setTimeout(() => {
              this.getSelect();
            }, 500);
          } else {
            if (res.sku && res.sku.length) {
              this.select = res.sku[0];
            }
          }
          // this.appendSku(res.sku[0].skuId)
          this.detail = res;
          let carousel = [];
          if (this.detail.spuVideo) {
            carousel.push({ type: "video", url: this.detail.spuVideo });
          }
          // 点击查看大图列表
          // let carousel=[]
          let srcList = [];
          for (let v of res.spuImgs) {
            carousel.push({ type: "image", url: v.imgUrl });
            srcList.push(this.$oucy.ossUrl + v.imgUrl);
          }
          this.carousel = carousel;
          this.srcList = srcList;
          // 查询店内分类
          this.findByLtUserAuthAndClassifyCategory();
          this.getBreadcrumb();
          this.findEnterpriseByLtUserAuthId();
          this.$oucy.setTitle(this.detail.spuName);
          this.getProductTicketForUser();
        });
      },
      // 选择分类
      setSelect(v, i, vv, ii) {
        if (vv.select) return;
        for (let vvv of v.furnitureAttrOption) {
          vvv.select = false;
        }
        vv.select = true;
        this.getSelect();
      },
      getSelect() {
        let attr = [];
        for (let v of this.detail.props) {
          for (let vv of v.furnitureAttrOption) {
            if (vv.select == true) {
              attr.push({
                key: v.id,
                value: vv.id,
              });
            }
          }
        }
        for (let vvv of this.detail.sku) {
          // 对比
          if (this.diff(attr, vvv)) {
            this.select = vvv;
            // console.log(vvv)
          }
        }
      },
      /**
       * 查询店内分类
       * @return {[type]} [description]
       */
      findByLtUserAuthAndClassifyCategory() {
        furnitureClassify
          .findByLtUserAuthAndClassifyCategory({
            enterpriseId: this.detail.enterpriseId,
          })
          .then((res) => {
            this.byLtUserAuthAndClassifyCategoryList = res;
          });
      },
      diff(ar, vvv) {
        let propPath = vvv.propPath.split(";");
        let attr = [];
        for (let vv of propPath) {
          let ar = vv.split(":");
          attr.push({
            key: ar[0],
            value: ar[1],
          });
        }

        let len = 0;
        for (let obj1 of ar) {
          for (let obj2 of attr) {
            if (this.$oucy.isObjectValueEqual(obj1, obj2)) {
              ++len;
            }
          }
        }

        return ar.length == len;
      },
      // 进货车相关
      addStock() {
        this.getAllScheme();
      },
      // 添加 方案
      createSchema() {
        stock.createSchema(this.ruleForm).then((res) => {
          if (res && res.schemeId) {
            this.AddStockValue = res.schemeId;
            this.appendSku();
          }
        });
      },
      // 查询 方案
      getAllScheme() {
        stock.getAllScheme({}).then((res) => {
          this.allScheme = res || [];
          if (this.allScheme && this.allScheme.length) {
            this.AddStockDialogVisible = true;
            this.AddStockValue = this.allScheme[0].schemeId;
          } else {
            this.createSchemaDialogVisible = true;
          }
        });
      },
      openDialogVisible(v) {
        this.dialogVisible = true;
      },
      closeDialogVisible() {
        this.dialogVisible = false;
      },
      submitForm() {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            // if (this.ruleForm.schemeId) {
            // this.modifySchemaBasicInfo()
            // } else {
            this.createSchema();
            // }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      appendSku() {
        stock
          .appendSku({ schemeId: this.AddStockValue, skuId: this.select.skuId })
          .then((res) => {
            this.AddStockDialogVisible = false;
            this.createSchemaDialogVisible = false;
            this.$message("添加成功");
            this.$oucy.app.eventBus.$emit(
              this.$oucy.msgKeys.UPDATA_STOCK_CAR_NUM,
              null
            );
          });
      },
      gotoCard() {
        cardEnterprise
          .getCardEnterpriseDetail({ id: this.detail.enterpriseId })
          .then((res) => {
            if (res) {
              this.$oucy.go(`/enterpriseDetail?id=${this.detail.enterpriseId}`);
            } else {
              this.$alert("您还没有创建企业名片，请先使用移动端创建企业名片");
            }
          });
      },
      getCommentSpuList() {
        spuComment.getCommentSpuList(this.QueryFurnitureLibrary).then((res) => {
          this.secondText = `产品评价(${res.totalElements})`;
          this.commentSpuList = res;
        });
      },

      SetOrderSchemeSpuSortEnum(v) {
        this.QueryFurnitureLibrary.orderSchemeSpuSortEnum = v;
        this.getCommentSpuList();
      },
      // 切换页数
      sizeChange: function (pageSize) {
        const me = this;
        me.QueryFurnitureLibrary.limit = pageSize;
        me.getCommentSpuList();
      },
      // 切换页码
      currentChange: function (current) {
        const me = this;
        me.QueryFurnitureLibrary.start = current - 1;
        me.getCommentSpuList();
      },
      showPhone() {
        this.$alert(
          this.enterpriseinfo.enterprisePhone || this.detail.telephone,
          "查看电话"
        );
        // this.$alert(this.detail.telephone,'查看电话')
      },
      // 查询企业
      findEnterpriseByLtUserAuthId() {
        enterpriseinfo
          .findEnterpriseByLtUserAuthId({
            enterpriseId: this.detail.enterpriseId,
          })
          .then((res) => {
            this.enterpriseinfo = res || {};
            this.queryEnterpriseCustomer(this.detail.enterpriseId);
          });
        let loginUser = localGet(this.$oucy.userInfoKey);
        if (loginUser && loginUser.id) {
          this.getIsCollect(this.detail.enterpriseId,1);
        }
      },
      /**
       * 查询企业客服
       * @param  {[type]} v [description]
       * @return {[type]}   [description]
       */
      queryEnterpriseCustomer(enterpriseId) {
        enterpriseinfo
          .queryEnterpriseCustomer({ enterpriseId: enterpriseId })
          .then((res) => {
            if (res && res.length) {
              this.enterpriseCustomer = res || [];
            } else {
            }
          });
      },
      openMessage() {
        this.enterpriseCustomer[0] &&
          this.openImMessage(this.enterpriseCustomer[0]);
      },
      /**
       * 打开聊天框
       * @param  {[type]} targetUserId [description]
       * @return {[type]}              [description]
       */
      openImMessage(v) {
        this.loginUser = localGet("loginUser");
        if (this.loginUser && this.loginUser.id) {
          this.$oucy.app.eventBus.$emit(this.$oucy.msgKeys.OPEN_IM_MESSAGE, {
            targetUserId: v.id,
          });
        } else {
          this.$alert("请先登录", "提示");
        }
        // this.$emit(this.$oucy.msgKeys.OPEN_IM_MESSAGE,v);
      },
      ourShop(v) {
        if (this.enterpriseinfo.vipLeve == 0) {
          this.$oucy.go(
            "/Shop?enterpriseId=" +
              this.detail.enterpriseId +
              "&navType=spu&spuName=" +
              v.keyword +
              "&keyword=" +
              v.keyword,
            null
          );
        } else {
          this.$oucy.go(
            "/ShopVip?enterpriseId=" +
              this.detail.enterpriseId +
              "&navType=spu&spuName=" +
              v.keyword +
              "&keyword=" +
              v.keyword,
            null
          );
        }
      },

      // 是否收藏 商品
      getIsCollect(id,collectType=1) {
        collectuser.isCollect({ collectId: id }).then((res) => {
          if(collectType==1){
            this.isCollect=res
          }
          if(collectType==2){
            this.isSpuCollect=res
          }
          // this.$message('操作成功')
        });
      },
      /**
       * 收藏企业 商品
       * @param  {[type]} v [description]
       * @return {[type]}   [description]
       */
      saveCollectUser(collectId,collectType=1) {
        collectuser
          .saveCollectUser({ collectType:collectType, collectId: collectId })
          .then((res) => {
            if(collectType==1){
              this.isCollect=!this.isCollect
            }
            if(collectType==2){
              this.isSpuCollect=!this.isSpuCollect
            }
            this.$message("操作成功");
          });
      },

      /**
       * 查询用户针对某款商品的优惠券列表
       * @param  {[type]} v [description]
       * @return {[type]}   [description]
       */
      getProductTicketForUser() {
        ticket.getProductTicketForUser({ spuId: this.spuId }).then((res) => {
          this.productTicketForUser = res || [];
        });
      },

      /**
       * 用户领取优惠券
       * @param  {[type]} v [description]
       * @return {[type]}   [description]
       */
      takeCoupons(v, i) {
        ticket.takeCoupons({ ticketId: v.ticketPoolBasic.id }).then((res) => {
          this.$message("领取成功");
          v.valid = !v.valid;
        });
      },
      gotoShop(v) {
        if (this.enterpriseinfo.vipLeve == 0) {
          this.$oucy.go(
            "/Shop?enterpriseId=" +
              this.detail.enterpriseId +
              "&navType=spu&classifyId=" +
              v.id +
              "&classifyName=" +
              v.classifyName,
            null
          );
        } else {
          this.$oucy.go(
            "/ShopVip?enterpriseId=" +
              this.detail.enterpriseId +
              "&navType=spu&classifyId=" +
              v.id +
              "&classifyName=" +
              v.classifyName,
            null
          );
        }
      },
      // 店铺
      decoderScene() {
        let obj = {
            objectId: this.detail.enterpriseId,
            playbillCategory: 9,
            redirectUrlScene:{id:this.detail.enterpriseId}
        };
        this.$refs.sharePoster.show(obj)

      },
      // 商品
      decoderScene2() {
        let obj = {
            objectId: this.spuId,
            playbillCategory: 7,
            redirectUrlScene:{id:this.spuId}
        };
        this.$refs.sharePoster.show(obj)
        
      },
      download(url = this.url) {
        let a = document.createElement("a");
        a.download = `${new Date().toLocaleString()}海报`;
        a.setAttribute("href", url);
        a.click();
      },
    },
  };
</script>
<style>
  .middle_top .left .el-carousel__container {
    height: 375px;
  }
</style>
<style scoped lang="less">

  .middle {
    background-color: #ffffff;
  }

  .middle_top {
    /*width: 76%;*/
    /*float: left;*/
    /*padding: 20px;*/
  }

  .left.el-carousel {
    width: 375px;
    height: 375px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .middle_top ul img {
    width: 64px;
  }

  .middle_top_left {
    /*margin-top: -400px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 25px;
  }

  /*设置左边的堆叠顺序，前提是要有定位，例如这里的position：relative*/
  .middle_top_left img {
    z-index: 1000;
    position: relative;
  }

  .middle_top_left ul {
    width: 200px;
    height: 64px;
    display: flex;
    overflow: hidden;
  }

  .middle_top_left li {
    /*display: inline-block;*/
    /*float: left;*/
    list-style-type: none;
    padding-left: 2px;
  }

  .middle_top_left li :after {
    border: 3px #2090ff solid;
  }

  .goods_conditon span {
    font-size: 18px;
    font-weight: bold;
  }

  .label1 {
    width: 66px;
    height: 24px;
    background: #f8f8f8;
    border-radius: 2px;
  }

  .price {
    /*width: 510px;*/
    /*height: 105px;*/
    background: #f8f8f8;
    margin-top: 10px;
  }

  .price p {
    width: 28px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-left: 15px;
  }
  .coupons {
    // width: 600px;
    // overflow: auto;
    flex-wrap: wrap;
  }
  .coupon {
    min-width: 80px;
    padding: 4px 15px;
    border-radius: 2px;
    position: relative;
    color: #fff;
    &:before {
      content: " ";
      display: inline-block;
      background: #fff;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      left: -10px;
      transform: translateY(-50%);
    }
    &:after {
      content: " ";
      display: inline-block;
      background: #fff;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      right: -10px;
      transform: translateY(-50%);
    }
  }
  .coupon0 {
    background: linear-gradient(135deg, #52a9ff 0%, #4785ff 100%);
  }
  .coupon1 {
    background: linear-gradient(147deg, #f8b43e 0%, #e68f1e 100%);
  }
  .coupon2 {
    background: linear-gradient(142deg, #b392ff 0%, #a178ff 100%);
  }

  .price_right span {
    width: 100px;
    height: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #aaaaaa;
    /*float: right;*/
    margin-top: 20px;
  }

  .p2 {
    width: 60px;
    height: 11px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }

  .goods_size span {
    width: 64px;
    height: 16px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin-top: 20px;
    float: left;
  }

  .size1 {
    /*width: 235px;*/
    /*height: 49px;*/
    line-height: 49px;
    background: rgba(255, 255, 255, 0);
    border: 1px solid #eaeaea;
    margin-top: 10px;
    position: relative;
  }

  .size1 img {
    float: left;
    width: 48px;
    height: 48px;
  }

  .size1 div {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 50px;
    margin: 0 auto;
  }

  .size2 img {
    float: left;
    margin-top: -66px;
  }

  .middle_right {
    /*float: right;*/
    /*padding: 20px;*/
  }

  .middle_right span {
    color: rgb(136, 136, 136);
  }

  .buy_button {
    margin-left: -10px;
    margin-top: 15px;
  }

  .buy_button .el-button {
    width: 150px;
    position: relative;
    z-index: 1000;
  }

  .middle_bottom_left {
    margin-left: 20px;
    width: 210px;
    float: left;
  }

  .middle_bottom_right img {
    margin-left: 50px;
  }

  .com_card {
    background-image: url("../../assets/goods/background.png");
    width: 210px;
    height: 150px;
    /*margin-top: 65px;*/
  }

  .com_card p {
    line-height: 40px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #666666;
    text-align: center;
    margin: 0 auto;
  }

  .com_card img {
    padding: 10px;
    margin-top: 10px;
  }

  .store_kind {
    border-spacing: 0;
    width: 210px;
    /*height: 280px;*/
    margin-top: 20px;
  }

  .store_kind th,
  td {
    height: 40px;
    border: 1px solid #eaeaea;
    text-align: center;
    line-height: 40px;
  }

  .store_kind th {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    background: #f8f8f8;
    color: #666666;
    text-align: center;
    line-height: 40px;
  }

  .store_kind .el-button {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    position: relative;
    z-index: 1000;
  }

  .customer_service {
    border-spacing: 0;
    width: 210px;
    // height: 280px;
    margin-top: 20px;
  }

  .customer_service th {
    font-family: Microsoft YaHei;
    height: 40px;
    font-size: 12px;
    font-weight: bold;
    background: #f8f8f8;
    color: #666666;
    border: 1px solid #eaeaea;
    text-align: center;
    line-height: 40px;
  }

  .customer_service td {
    height: 60px;
    border: 1px solid #eaeaea;
    // margin-top: 30px;
    text-align: center;
    line-height: 60px;
  }

  .customer_service img {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    float: left;
  }

  .customer_service .el-button {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    position: relative;
    z-index: 1000;
  }

  .sales_rank {
    border-spacing: 0;
    width: 210px;
    margin-top: 20px;
    /*height: 1302px;*/
    background: #ffffff;
    border: 1px solid #efdfca;
    border-collapse: collapse;
    padding-bottom: 20px;
  }

  .sales_rank th {
    width: 210px;
    height: 48px;
    background: linear-gradient(0deg, #fffdf8, #fff6ea);
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }

  .sales_rank td {
    border: none;
  }

  .goods_name {
    width: 188px;
    height: 40px;
    background: #fafafa;
    margin: 0 auto;
    position: relative;
    top: -8px;
  }

  .goods_name span {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    text-align: center;
    line-height: 40px;
  }

  .goods_name2 {
    /*width: 188px;*/
    /*height: 40px;*/
    background: #fafafa;
    /*margin: -5px auto;*/
  }

  .goods_name2 span {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    text-align: center;
    line-height: 40px;
  }

  .top {
    // width: 820px;
    // height: 32px;
    background: #f8f8f8;
    padding: 10px;
    // margin-left: 80px;
    // margin-top: 20px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #666666;
  }

  .top p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #666666;
    line-height: 11px;
    text-align: center;
    float: left;
    margin-left: 22px;
  }

  .goods_condition {
    width: 650px;
    float: left;
    margin-left: 20px;
  }

  .goods_condition td {
    width: 140px;
    text-align: left;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    border: none;
  }

  .goods_comment /deep/ .el-tabs {
    width: 930px;
    margin-left: 20px;
  }

  .goods_comment /deep/ .el-tabs__item {
    width: 140px;
    height: 39px;
    background: #f3f3f3;
    border: none;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }

  .goods_comment /deep/ .el-tabs__item.is-active {
    background: #2090ff;
    color: white;
  }

  .goods_comment /deep/ .el-tabs__nav-wrap {
    height: 41px;
    background: #2090ff;
  }

  .goods_comment /deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: none;
  }

  .goods_comment /deep/ .el-tabs__nav-scroll {
    background-color: #f8f8f8;
  }

  .goods_comment /deep/ .el-form-item__label {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    width: 10px;
  }

  // /deep/ .el-divider {
  //     width: 820px;
  //     margin-left: 80px;
  // }

  .vhtml /deep/ img {
    max-width: 100%;
  }
  .vhtml /deep/ img + img {
    margin-top: -4px;
  }

  .right_button {
    text-align: right;
    // float: right;
    margin-right: 30px;
  }
  .right_button .el-button.select {
    color: #2894ff;
  }
  .right_button .el-button {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }

  .right_button .el-button:focus {
    font-weight: bold;
    color: #2894ff !important;
  }

  .comment {
    // float: right;
    // margin-right: 30px;
  }

  .comment_1 {
    background: #f8f8f8;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .comment_right {
    padding: 10px;
    text-align: left;
  }

  .comment_right_p1 {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 1.5;
  }

  .comment_right span {
    font-size: 14px;
    font-family: SimSun;
    font-weight: 400;
    color: #aaaaaa;
    line-height: 52px;
  }

  .comment_img {
    margin-top: 10px;
  }

  .comment_reply {
    background: #fff;
    // background-image: url("../../assets/background/reply_bk.png");
    // background-repeat: no-repeat;
    // background-size:100% 100%;
    padding: 20px 30px;
    color: #999;
  }

  .comment_reply span {
    float: left;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #5b6069;
    line-height: 20px;
    margin-left: -8px;
  }

  .comment_reply p {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 4px;
  }

  .comment_reply img {
    margin-top: 0px;
    margin-left: 0px;
  }

  .username {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-left: 10px;
  }

  .footer_footer {
    margin-top: 150px;
  }

  .footer_footer table {
    margin: 0px auto;
  }

  .footer_footer table td {
    border: none;
    text-align: left;
  }

  .footer_footer span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #888888;
    padding: 10px;
  }

  .drawer_left_top {
    width: 220px;
    height: 64px;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cart_left {
    width: 220px;
    height: 935px;
    margin-top: -20px;
    background-color: #f4f4f4;
  }

  .product_name ul li:hover {
    background: #ffffff;
  }

  .product_name ul li {
    width: 180px;
    height: 96px;
    list-style: none;
    cursor: pointer;
    margin-left: -40px;
    padding: 20px 20px;
  }

  .product_name span {
    line-height: 30px;
  }

  .product_condition {
    text-align: left;
    margin-top: -15px;
  }

  .product_condition p {
    width: 207px;
    height: 29px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }

  .product_condition .span1 {
    width: 132px;
    height: 12px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #aaaaaa;
  }

  .product_condition .span2 {
    width: 63px;
    height: 12px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ed6732;
  }

  /*/deep/ .el-input-number__inner {
    width: 20px;
}*/

  .fangan_name {
    height: 18px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    float: left;
    padding: 20px;
  }

  /*头部*/
  .topNav {
    background: #fff;
    padding: 10px 0;
  }

  .df-jc-s-b {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    height: 58px;
    width: 163px;
  }

  .input-container {
    // width: 500px;
    /*margin: 0 auto;*/
  }

  .topCenter {
    background: url("../../assets/images/product/bG.png");
    background-size: 100%;
  }

  .topCenterBody {
    height: 216px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    text-align: left;
  }

  .merchantLogo {
    width: 128px;
    height: 128px;
  }

  .merchantText {
    flex: 1;
  }

  .topBottom {
    width: 100%;
    background: #fff;
  }

  .breadcrumbBarBox {
    padding: 3px;

    background: #f5f6f7;
  }

  .breadcrumbBar,
  .breadcrumb-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-family: SimSun;
    font-weight: 400;
    color: #666666;
  }

  .z-skus {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .z-sku {
    /*width: 30%;*/
    /*padding-right: 10px;*/
    box-sizing: border-box;
    margin-right: 10px;
  }

  .z-sku .optionValue {
    padding-right: 10px;
  }

  .lisColor {
    box-sizing: border-box;
    border: 3px solid #2090ff;
  }

  .notlisColor {
    box-sizing: border-box;
    border: 3px solid #fff;
  }

  /*头部*/

  .el-select .el-input {
    width: 130px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .product.topBottom .el-menu.el-menu--horizontal {
    border: none;
    font-size: 16px;
    color: #333;
  }

  .product .el-menu-item,
  .product /deep/ .el-submenu__title {
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }

  .product .el-menu--horizontal > .el-menu-item {
  }

  .product .el-menu--horizontal > .el-submenu .el-submenu__title {
    /*font-size: 16px;*/
    /*color: #333;*/
  }

  .size1 img.corner {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    bottom: 0;
    display: none;
  }

  .size1.select {
    border-color: #2090ff;
  }

  .size1.select img.corner {
    display: inline-block;
  }

  .rightCarouseBox {
    width: 100%;
  }

  .rightCarouselImg {
    width: 100%;
    /*width: 118px;*/
    /*height: 118px;*/
  }

  .guessWhatYouLike {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #888888;
    display: inline-block;
    margin-bottom: 10px;
  }

  .hLine {
    display: inline-block;
    width: 5px;
    height: 14px;
    background: #2090ff;
    border-radius: 1px;
    margin-right: 5px;
  }

  .hr.dashed {
    margin: 20px 0;
    border-top: 1px dashed #eaeaea;
  }

  .clickImgBox {
    position: relative;
  }

  .clickImg {
    position: absolute;
    left: 0;
    top: 0;
    width: 375px;
    height: 375px;
    z-index: 98;
  }
</style>
